import { useAuth } from "../contexts/AuthContext";
import {
  HomeIcon,
  GiftIcon,
  PhotoIcon,
  TrophyIcon,
  DevicePhoneMobileIcon,
  UserCircleIcon,
  CreditCardIcon,
  ArrowRightOnRectangleIcon,
  TagIcon,
  UsersIcon,
  BuildingStorefrontIcon,
  UserGroupIcon,
  BanknotesIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SunIcon,
  MoonIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";

const ownerMenuItems = [
  {
    title: "Dashboard",
    icon: HomeIcon,
    view: "dashboard",
  },
  {
    title: "Gift Cards",
    icon: GiftIcon,
    view: "giftcards",
  },
  {
    title: "Offers",
    icon: TagIcon,
    view: "offers",
  },
  {
    title: "Staff",
    icon: UserGroupIcon,
    view: "staff",
  },
  {
    title: "Gallery",
    icon: PhotoIcon,
    view: "gallery",
  },
  {
    title: "Subscribers",
    icon: UsersIcon,
    view: "subscribers",
  },
  {
    title: "Reward Plan",
    icon: TrophyIcon,
    view: "rewardplan",
  },
  {
    title: "Transactions",
    icon: BanknotesIcon,
    view: "transactions",
  },
  {
    title: "Stores",
    icon: BuildingStorefrontIcon,
    view: "stores",
  },
  {
    title: "Profile",
    icon: UserCircleIcon,
    view: "profile",
  },
  {
    title: "Devices",
    icon: DevicePhoneMobileIcon,
    view: "devices",
  },
  {
    title: "Billing",
    icon: CreditCardIcon,
    view: "billing",
  },
];

const staffMenuItems = [
  {
    title: "Dashboard",
    icon: HomeIcon,
    view: "dashboard",
  },
  {
    title: "Reward Plan",
    icon: TrophyIcon,
    view: "rewardplan",
  },
];

const Sidebar = ({
  className = "",
  isOpen,
  isDesktop,
  onClose,
  onToggle,
  onNavigate,
  showCollapseButton,
  onLogout,
  onThemeToggle,
  isDarkMode,
  activeView,
}) => {
  const { user } = useAuth();
  const isOwner = user?.userType === "Owner";

  const renderMenuItems = isOwner ? ownerMenuItems : staffMenuItems;

  return (
    <div
      className={`${className} flex flex-col h-full ${
        isDarkMode ? "bg-slate-800" : "bg-white"
      }`}
    >
      {/* Logo */}
      <div className="flex-shrink-0 px-3 py-4 flex items-center justify-between">
        <img className="h-8 w-auto" src="/images/logo.png" alt="Loyali App" />
        {showCollapseButton && (
          <button
            onClick={onToggle}
            className={`p-2 rounded-md ${
              isDarkMode
                ? "text-slate-400 hover:text-slate-200 hover:bg-slate-700"
                : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
            }`}
          >
            {isOpen ? (
              <ChevronLeftIcon className="h-5 w-5" />
            ) : (
              <ChevronRightIcon className="h-5 w-5" />
            )}
          </button>
        )}
      </div>

      {/* Navigation Menu */}
      <nav className="flex-1 px-2 py-4 space-y-1 overflow-y-auto">
        {renderMenuItems.map((item) => (
          <button
            key={item.view}
            onClick={() => onNavigate(item.view)}
            className={`w-full flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors ${
              activeView === item.view
                ? isDarkMode
                  ? "bg-slate-700 text-white"
                  : "bg-gray-100 text-gray-900"
                : isDarkMode
                ? "text-slate-300 hover:bg-slate-700 hover:text-white"
                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
            }`}
          >
            <item.icon
              className={`mr-3 flex-shrink-0 h-6 w-6 ${
                activeView === item.view
                  ? isDarkMode
                    ? "text-white"
                    : "text-gray-900"
                  : isDarkMode
                  ? "text-slate-400"
                  : "text-gray-400"
              }`}
            />
            {isOpen && <span className="truncate">{item.title}</span>}
          </button>
        ))}

        {/* Chat Button */}
        {/* <button
          onClick={() => onNavigate('chat')}
          className={`w-full flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors ${
            isDarkMode
              ? "text-slate-300 hover:bg-slate-700 hover:text-white"
              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
          }`}
        >
          <ChatBubbleLeftRightIcon
            className={`mr-3 h-6 w-6 ${
              isDarkMode ? "text-slate-400" : "text-gray-400"
            }`}
          />
          {isOpen && <span>Chat</span>}
        </button> */}
      </nav>

      {/* Bottom Actions */}
      <div className="flex-shrink-0 p-2 space-y-1">
        {/* Theme Toggle */}
        <button
          onClick={onThemeToggle}
          className={`w-full flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors ${
            isDarkMode
              ? "text-slate-300 hover:bg-slate-700 hover:text-white"
              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
          }`}
        >
          {isDarkMode ? (
            <>
              <SunIcon className="mr-3 h-6 w-6 text-slate-400" />
              {isOpen && <span>Light Mode</span>}
            </>
          ) : (
            <>
              <MoonIcon className="mr-3 h-6 w-6 text-gray-400" />
              {isOpen && <span>Dark Mode</span>}
            </>
          )}
        </button>

        {/* Logout Button */}
        <button
          onClick={onLogout}
          className={`w-full flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors ${
            isDarkMode
              ? "text-slate-300 hover:bg-slate-700 hover:text-white"
              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
          }`}
        >
          <ArrowRightOnRectangleIcon
            className={`mr-3 h-6 w-6 ${
              isDarkMode ? "text-slate-400" : "text-gray-400"
            }`}
          />
          {isOpen && <span>Logout</span>}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
