import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../contexts/ThemeContext";
import {
  XMarkIcon,
  CreditCardIcon,
  TicketIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline";
import customerService from "../services/customerService";
import { toast } from "react-hot-toast";
import SuccessErrorModal from "../components/SuccessErrorModal";

const CustomerDetails = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const location = useLocation();
  const navigate = useNavigate();
  const customer = location.state?.customer;
  const [loading, setLoading] = useState(true); // Loading state
  const [customerDetails, setCustomerDetails] = useState(null);

  const [billingAmount, setBillingAmount] = useState("");
  const [walletRedeemAmount, setWalletRedeemAmount] = useState("");
  const [giftcardRedeemAmount, setGiftcardRedeemAmount] = useState("");
  const [selectedAction, setSelectedAction] = useState("reward");
  const [selectedGiftcard, setSelectedGiftcard] = useState(null);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [refundAmount, setRefundAmount] = useState("");
  const [refundConfirmed, setRefundConfirmed] = useState(false);
  const [isRewardConfirmed, setIsRewardConfirmed] = useState(false);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [authorizationStatus, setAuthorizationStatus] = useState(null);
  const [balanceCollected, setBalanceCollected] = useState(false);
  const [rewardSuccess, setRewardSuccess] = useState(false);
  const [rewardError, setRewardError] = useState(false);
  const [sellSuccess, setSellSuccess] = useState(false);
  const [sellError, setSellError] = useState(false);
  const [refundSuccess, setRefundSuccess] = useState(false);
  const [refundError, setRefundError] = useState(false);
  const [availableGiftcards, setAvailableGiftcards] = useState([]);
  const [isLoadingGiftcards, setIsLoadingGiftcards] = useState(false);
  const [rtId, setRtId] = useState(null); // Add state for rtId
  const [pollingIntervalId, setPollingIntervalId] = useState(0);

  const [walletBalance, setWalletBalance] = useState(
    customer.walletBalance || 0
  );
  const [giftcardBalance, setGiftcardBalance] = useState(
    customer.giftcardBalance || 0
  );

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Reset state when a new customer is searched
    setBillingAmount("");
    setWalletRedeemAmount("");
    setGiftcardRedeemAmount("");
    setSelectedAction("reward");
    setSelectedGiftcard(null);
    setPaymentConfirmed(false);
    setRefundAmount("");
    setRefundConfirmed(false);
    setIsRewardConfirmed(false);
    setAuthorizationStatus(null);
    if(rtId) {
      customerService.deleteRewardTransaction({ rtId });
      setRtId(null);
    }

    // Clear any previous polling status interval
    if(pollingIntervalId > 0) {console.log(`In use effect pollingIntervalId: ${pollingIntervalId}`); clearInterval(pollingIntervalId)};

    // Update wallet and gift card balances when customer changes
    setWalletBalance(customer.walletBalance || 0);
    setGiftcardBalance(customer.giftcardBalance || 0);
  }, [customer]);

  useEffect(() => {
    const fetchGiftcards = async () => {
      setIsLoadingGiftcards(true);
      try {
        const response = await customerService.getPublishedGiftcards();
        // Check if response is an array
        if (Array.isArray(response)) {
          const formattedGiftcards = response.map((gc) => ({
            id: gc.id, // Use _id from the response
            amount: gc.faceValue, // Use faceValue for the amount
            discount: gc.discount, // Use discount directly
            validity: gc.validity ? `${gc.validity} Days` : "Never Expires", // Format validity
            actualAmount: gc.actualValue, // Use actualValue for the actual amount
          }));
          setAvailableGiftcards(formattedGiftcards);
          console.log("Available Giftcards:", formattedGiftcards);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Failed to fetch giftcards:", error);
      } finally {
        setIsLoadingGiftcards(false);
      }
    };

    fetchGiftcards();
  }, []);

  const handleCancel = () => {
    try{
    // Clear any previous polling status interval
    if(pollingIntervalId > 0) {console.log(`In handleCancel pollingIntervalId: ${pollingIntervalId}`); clearInterval(pollingIntervalId)};
    if(rtId) {
      customerService.deleteRewardTransaction({ rtId });
      setRtId(null);
    }
    navigate(-1);
  } catch (error) {
    toast.error(`${error.message}`);
  }
  };

  const handleNext = async () => {
    if (selectedAction === "reward") {
      if (billingAmount && (walletRedeemAmount || giftcardRedeemAmount)) {
        // Prepare the request body for the API call
        const requestBody = {
          customerId: customer.id,
          billingAmount: billingAmount,
          gc: giftcardRedeemAmount || "0",
          wallet: walletRedeemAmount || "0",
          rtId: rtId || "", // Assuming this is optional or can be left empty
        };

        try {
          // Call the API to create a reward transaction
          const response = await customerService.createRewardTransaction(
            requestBody
          );
          const newRtId = response.rtId; // Extract rtId from the response
          setRtId(newRtId); // Store rtId in state
          startPollingStatus(newRtId); // Start polling for status
          
          setIsRewardConfirmed(true);
          const balance =
            parseFloat(billingAmount) -
            parseFloat(walletRedeemAmount || "0") -
            parseFloat(giftcardRedeemAmount || "0");
          setBalanceAmount(balance.toFixed(2));
          setAuthorizationStatus("pending");

        } catch (error) {
          console.error("Error creating reward transaction:", error);
          toast.error(`${error.message}`);
          // Handle error (e.g., show an error message)
        }
      } else {
        setIsRewardConfirmed(true);
        setBalanceAmount(parseFloat(billingAmount).toFixed(2));
      }
    } else {
      // TODO: Implement Next functionality for other actions
    }
  };

  // New function to start polling for transaction status
  const startPollingStatus = (rtId) => {
    const intervalId = setInterval(async () => {
      try {
        const statusResponse = await customerService.getRewardTransactionStatus(
          { rtId }
        );
        const status = statusResponse.status;

        if (status === "CUSTOMER_APPROVED") {
          setAuthorizationStatus("authorized");
          clearInterval(intervalId); // Stop polling
        } else if (status === "CUSTOMER_REJECTED") {
          setAuthorizationStatus("rejected"); // Set status to rejected
          clearInterval(intervalId); // Stop polling
        } else if (status === "INITIATED") {
          setAuthorizationStatus("pending"); // Set status to pending
        } else {
          setAuthorizationStatus(status); // Set status to pending
        }
      } catch (error) {
        console.error("Error fetching transaction status:", error);
        setAuthorizationStatus("error"); // Set status to error if fetching fails
        clearInterval(intervalId); // Stop polling on error
      }
    }, 5000); // Poll every 5 seconds
    setPollingIntervalId(intervalId);
  };

  // New function to refresh the transaction status
  const handleRefreshStatus = async () => {
    if (rtId) {
      // Check if rtId is available
      try {
        const statusResponse = await customerService.getRewardTransactionStatus(
          { rtId }
        );
        const status = statusResponse.status;

        if (status === "CUSTOMER_APPROVED") {
          setAuthorizationStatus("authorized");
        } else if (status === "CUSTOMER_REJECTED") {
          setAuthorizationStatus("rejected"); // Set status to rejected
        } else {
          setAuthorizationStatus("pending"); // Set status to pending
        }
      } catch (error) {
        console.error("Error fetching transaction status:", error);
        setAuthorizationStatus("error"); // Set status to error if fetching fails
      }
    }
  };

  // const handleRewardCancel = () => {
  //   setIsRewardConfirmed(false);
  //   setBillingAmount("");
  //   setWalletRedeemAmount("");
  //   setGiftcardRedeemAmount("");
  //   setBalanceAmount(0);
  //   setAuthorizationStatus(null);
  //   setBalanceCollected(false);
  //   if(pollingIntervalId > 0) {console.log(`In handleCancel pollingIntervalId: ${pollingIntervalId}`); clearInterval(pollingIntervalId)};
  //   navigate(-1);
  // };

  const fetchCustomerDetails = async () => {
    try {
      const response = await customerService.searchCustomers(customer.id);
      if (response) {
        // Assuming the response contains updated wallet and gift card balances
        setWalletBalance(response.walletBalance || 0);
        setGiftcardBalance(response.gcBalance || 0);
      }
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };

  const handleReward = async () => {
    if (!customer?.id || !billingAmount) return;

    try {
      // Only call reward API if no redeem amounts are set
      if (!walletRedeemAmount && !giftcardRedeemAmount) {
        await customerService.rewardCustomer(customer.id, billingAmount);
        setRewardSuccess(true);
        setMessage("Reward transaction completed successfully!");
      } else {
        // New API call to reward customer with redeem amounts
        const requestBody = {
          customerId: customer.id,
          billingAmount: parseFloat(billingAmount).toFixed(2),
          rtId: rtId || "", // Use existing rtId or empty if not available
        };
        await customerService.rewardCustomerWithRedeem(requestBody); // New API call
        setRewardSuccess(true);
        setMessage("Reward transaction with redeem amounts completed successfully!");
      }
      setShowMessageModal(true);
      // Reset the form
      setBillingAmount("");
      setGiftcardRedeemAmount("");
      setWalletRedeemAmount("");
      setIsRewardConfirmed(false);
      // Close the page after a short delay
      setTimeout(() => {
        navigate(-1); // or navigate to a specific route
      }, 2000); // Adjust the delay as needed
    } catch (error) {
      setRewardError(true);
      setMessage("Error completing the reward transaction.");
      setShowMessageModal(true);
      // Close the page after a short delay
      setTimeout(() => {
        navigate(-1); // or navigate to a specific route
      }, 2000); // Adjust the delay as needed
    }
  };

  const closeMessageModal = () => {
    setShowMessageModal(false);
    setMessage("");
  };

  // const handleRewardSuccessModalClose = () => {
  //   setRewardSuccess(false);
  //   handleRewardCancel();
  // };

  // const handleRewardErrorModalRetry = () => {
  //   setRewardError(false);
  //   handleReward();
  // };

  // const handleRewardErrorModalCancel = () => {
  //   setRewardError(false);
  //   handleRewardCancel();
  // };

  const handleSell = async () => {
    if (!selectedGiftcard || !customer?.id) return;

    try {
      await customerService.sellGiftcard(customer.id, selectedGiftcard.id);
      setSellSuccess(true);
      setMessage("Giftcard sold successfully!");
      setShowMessageModal(true);
      setSelectedGiftcard(null);
      setPaymentConfirmed(false);
      
      // Close the page after a short delay
      setTimeout(() => {
        navigate(-1); // or navigate to a specific route
      }, 2000); // Adjust the delay as needed
    } catch (error) {
      setSellError(true);
      setMessage("Error selling the giftcard.");
      setShowMessageModal(true);
    }
  };

  const handleSellSuccessModalClose = () => {
    setSellSuccess(false);
    handleSellCancel();
  };

  const handleSellErrorModalRetry = () => {
    setSellError(false);
    handleSell();
  };

  const handleSellErrorModalCancel = () => {
    setSellError(false);
    handleSellCancel();
  };

  const handleSellCancel = () => {
    setSelectedGiftcard(null);
    setPaymentConfirmed(false);
  };

  const isNextDisabled =
    selectedAction === "sell"
      ? !selectedGiftcard || !paymentConfirmed
      : selectedAction === "refund"
      ? !refundAmount || parseFloat(refundAmount) <= 0 || !refundConfirmed
      : !billingAmount ||
        parseFloat(billingAmount) <= 0 ||
        parseFloat(walletRedeemAmount || "0") +
          parseFloat(giftcardRedeemAmount || "0") >
          parseFloat(billingAmount);

  const handleBillingAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setBillingAmount(value);
    }
  };

  const handleWalletRedeemAmountChange = (e) => {
    const value = e.target.value;
    if (
      /^\d*\.?\d{0,2}$/.test(value) &&
      (value === "" || (parseFloat(value) >= 0 && parseFloat(value) <= walletBalance))
    ) {
      setWalletRedeemAmount(value);
    }
  };

  const handleGiftcardRedeemAmountChange = (e) => {
    const value = e.target.value;
    if (
      /^\d*\.?\d{0,2}$/.test(value) &&
      (value === "" || (parseFloat(value) >= 0 && parseFloat(value) <= giftcardBalance))
    ) {
      setGiftcardRedeemAmount(value);
    }
  };

  const handleGiftcardChange = (e) => {
    const selectedId = e.target.value;
    const giftcard = availableGiftcards.find((gc) => gc.id === selectedId);
    setSelectedGiftcard(giftcard);
  };

  const handleRefundAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setRefundAmount(value);
    }
  };

  const handleActionChange = (action) => {
    setSelectedAction(action);
    if (action === "reward") {
      fetchCustomerDetails(); // Fetch customer details when reward action is selected
    }
    if (action !== "reward") {
      setBillingAmount("");
      setWalletRedeemAmount("");
      setGiftcardRedeemAmount("");
    }
    if (action !== "sell") {
      setSelectedGiftcard(null);
      setPaymentConfirmed(false);
    }
    if (action !== "refund") {
      setRefundAmount("");
      setRefundConfirmed(false);
    }
  };

  const isRedeemAmountExceeded =
    parseFloat(walletRedeemAmount || "0") +
      parseFloat(giftcardRedeemAmount || "0") >
    parseFloat(billingAmount);

  const handleRefund = async () => {
    if (!customer?.id || !refundAmount) return;

    try {
      await customerService.refundCustomer(customer.id, refundAmount);
      setRefundSuccess(true);
      setMessage("Refund processed successfully!");
      setShowMessageModal(true);
      setRefundAmount("");
      setRefundConfirmed(false);
      
      // Close the page after a short delay
      setTimeout(() => {
        navigate(-1); // or navigate to a specific route
      }, 2000); // Adjust the delay as needed
    } catch (error) {
      setRefundError(true);
      setMessage("Error processing the refund.");
      setShowMessageModal(true);
    }
  };

  const handleRefundSuccessModalClose = () => {
    setRefundSuccess(false);
    handleRefundCancel();
    navigate(-1);
  };

  const handleRefundErrorModalRetry = () => {
    setRefundError(false);
    handleRefund();
  };

  const handleRefundErrorModalCancel = () => {
    setRefundError(false);
    handleRefundCancel();
  };

  const handleRefundCancel = () => {
    setRefundAmount("");
    setRefundConfirmed(false);
  };

  if (!customer) {
    return (
      <div className="container mx-auto px-4 py-6">
        <h1
          className={`text-2xl font-semibold mb-4 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          Customer Details
        </h1>
        <p className={`${isDarkMode ? "text-slate-400" : "text-gray-500"}`}>
          No customer data available.
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mb-6">
        <div className="flex items-center justify-between mb-4 lg:mb-0 lg:w-auto">
          <h1
            className={`text-2xl font-semibold ${
              isDarkMode ? "text-white" : "text-gray-800"
            }`}
          >
            {customer.name}{" "}
            <span className="text-lg font-normal">({customer.id})</span>
          </h1>
          <button
            onClick={handleCancel}
            className={`p-2 rounded-lg ml-4 lg:hidden ${
              isDarkMode
                ? "text-slate-400 hover:text-slate-200"
                : "text-gray-500 hover:text-gray-700"
            }`}
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="flex justify-center lg:flex-1 lg:mx-8">
          <div className="flex space-x-4">
            <label className="flex items-center">
              <input
                type="radio"
                value="reward"
                checked={selectedAction === "reward"}
                onChange={() => handleActionChange("reward")}
                className="mr-2"
              />
              <TrophyIcon className="h-5 w-5 mr-1" />
              Reward
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="sell"
                checked={selectedAction === "sell"}
                onChange={() => handleActionChange("sell")}
                className="mr-2"
              />
              <CreditCardIcon className="h-5 w-5 mr-1" />
              Sell
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="refund"
                checked={selectedAction === "refund"}
                onChange={() => handleActionChange("refund")}
                className="mr-2"
              />
              <TicketIcon className="h-5 w-5 mr-1" />
              Refund
            </label>
          </div>
        </div>
        <div className="hidden lg:block">
          <button
            onClick={handleCancel}
            className={`p-2 rounded-lg ${
              isDarkMode
                ? "text-slate-400 hover:text-slate-200"
                : "text-gray-500 hover:text-gray-700"
            }`}
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
      </div>

      <div
        className={`p-6 rounded-lg ${isDarkMode ? "bg-slate-800" : "bg-white"}`}
      >
        <h2
          className={`text-xl font-semibold text-center mb-4 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          {selectedAction === "reward"
            ? "Reward Customer"
            : selectedAction === "sell"
            ? "Sell Giftcards"
            : "Refund Customer"}
        </h2>
        {selectedAction === "sell" ? (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Select Gift Card to Sell
              </label>
              <select
                value={selectedGiftcard?.id || ""}
                onChange={handleGiftcardChange}
                className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 ${
                  isDarkMode
                    ? "bg-slate-700 text-white"
                    : "bg-white text-gray-900"
                }`}
                disabled={isLoadingGiftcards}
              >
                <option value="" disabled>
                  Select a gift card
                </option>
                {isLoadingGiftcards ? (
                  <option>Loading...</option>
                ) : (
                  availableGiftcards.map((gc) => (
                    <option key={gc.id} value={gc.id}>
                      {gc.amount} (Discount: {gc.discount}%)
                    </option>
                  ))
                )}
              </select>
            </div>
            {selectedGiftcard && (
              <div className="mb-4">
                <h3
                  className={`text-lg font-semibold mb-2 ${
                    isDarkMode ? "text-white" : "text-gray-800"
                  }`}
                >
                  Giftcard Details
                </h3>
                <p
                  className={`mb-1 ${
                    isDarkMode ? "text-slate-400" : "text-gray-700"
                  }`}
                >
                  Amount: ${selectedGiftcard.amount}
                </p>
                <p
                  className={`mb-1 ${
                    isDarkMode ? "text-slate-400" : "text-gray-700"
                  }`}
                >
                  Discount: {selectedGiftcard.discount}%
                </p>
                <p
                  className={`mb-1 ${
                    isDarkMode ? "text-slate-400" : "text-gray-700"
                  }`}
                >
                  Validity: {selectedGiftcard.validity}
                </p>
                <p
                  className={`mb-1 ${
                    isDarkMode ? "text-slate-400" : "text-gray-700"
                  }`}
                >
                  Actual Amount: ${selectedGiftcard.actualAmount}
                </p>
              </div>
            )}
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={paymentConfirmed}
                  onChange={(e) => setPaymentConfirmed(e.target.checked)}
                  className="mr-2"
                  required
                />
                <span
                  className={`${
                    isDarkMode ? "text-slate-400" : "text-gray-700"
                  }`}
                >
                  I confirm that the payment for this gift card has been
                  successfully received. <span className="text-red-500">*</span>
                </span>
              </label>
            </div>
          </>
        ) : selectedAction === "refund" ? (
          <>
            <div className="mb-4">
              <label
                htmlFor="refundAmount"
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-400" : "text-gray-700"
                }`}
              >
                Enter Refund Amount <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                inputMode="numeric"
                id="refundAmount"
                value={refundAmount}
                onChange={handleRefundAmountChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300 text-gray-900"
                }`}
                required
              />
            </div>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={refundConfirmed}
                  onChange={(e) => setRefundConfirmed(e.target.checked)}
                  className="mr-2"
                  required
                />
                <span
                  className={`${
                    isDarkMode ? "text-slate-400" : "text-gray-700"
                  }`}
                >
                  I confirm to process this refund{" "}
                  <span className="text-red-500">*</span>
                </span>
              </label>
            </div>
          </>
        ) : (
          <>
            <div className="mb-4">
              <label
                htmlFor="billingAmount"
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-400" : "text-gray-700"
                }`}
              >
                Enter Billing Amount <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                inputMode="numeric"
                id="billingAmount"
                value={billingAmount}
                onChange={handleBillingAmountChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300 text-gray-900"
                }`}
                required
                disabled={isRewardConfirmed}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="walletRedeemAmount"
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-400" : "text-gray-700"
                }`}
              >
                Wallet Redeem Amount{" "}
                <span
                  className={`ml-2 ${
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  }`}
                >
                  (${walletBalance})
                </span>
              </label>
              <input
                type="text"
                inputMode="numeric"
                id="walletRedeemAmount"
                value={walletRedeemAmount}
                onChange={handleWalletRedeemAmountChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300 text-gray-900"
                } ${isRedeemAmountExceeded ? "border-red-500" : ""}`}
                disabled={isRewardConfirmed}
              />
              {isRedeemAmountExceeded && (
                <p className="text-red-500 text-sm mt-1">
                  The sum of wallet and gift card redeem amounts cannot exceed
                  the billing amount.
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="giftcardRedeemAmount"
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-400" : "text-gray-700"
                }`}
              >
                Giftcard Redeem Amount{" "}
                <span
                  className={`ml-2 ${
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  }`}
                >
                  (${giftcardBalance})
                </span>
              </label>
              <input
                type="text"
                inputMode="numeric"
                id="giftcardRedeemAmount"
                value={giftcardRedeemAmount}
                onChange={handleGiftcardRedeemAmountChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300 text-gray-900"
                } ${isRedeemAmountExceeded ? "border-red-500" : ""}`}
                disabled={isRewardConfirmed}
              />
              {isRedeemAmountExceeded && (
                <p className="text-red-500 text-sm mt-1">
                  The sum of wallet and gift card redeem amounts cannot exceed
                  the billing amount.
                </p>
              )}
            </div>
            {isRewardConfirmed && (
              <>
                <div className="mb-4">
                  <p
                    className={`text-lg font-semibold ${
                      isDarkMode ? "text-white" : "text-gray-800"
                    }`}
                  >
                    Balance Amount: ${balanceAmount}
                  </p>
                </div>
                {authorizationStatus && (
                  <div className="mb-4">
                    {rtId && (
                      <div className="my-2">
                        <span className="font-medium">Transaction ID:</span>{" "}
                        {rtId}
                      </div>
                    )}
                    <label
                      className={`block mb-2 ${
                        isDarkMode ? "text-slate-400" : "text-gray-700"
                      }`}
                    >
                      Customer Authorization
                    </label>
                    <div className="flex items-center">
                      <span
                        className={`mr-2 ${
                          authorizationStatus === "pending"
                            ? "text-yellow-500"
                            : authorizationStatus === "authorized"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {authorizationStatus === "pending"
                          ? "Pending"
                          : authorizationStatus === "authorized"
                          ? "Authorized"
                          : (
                            <>
                              <XMarkIcon className="h-5 w-5 text-red-500 inline-block mr-1" />
                              Rejected
                            </>
                          )}
                      </span>
                      {authorizationStatus === "pending" && (
                        <svg
                          className="animate-spin h-5 w-5 text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      {authorizationStatus === "authorized" && (
                        <svg
                          className="h-5 w-5 text-green-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {authorizationStatus === "pending" && (
                        <button
                          onClick={handleRefreshStatus}
                          className="ml-2 text-blue-500 hover:text-blue-600 focus:outline-none"
                        >
                          Refresh
                        </button>
                      )}
                      {authorizationStatus === "rejected" && (
                        <button
                          onClick={handleNext}
                          className="ml-2 text-blue-500 hover:text-blue-600 focus:outline-none"
                        >
                          Try Again
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {parseFloat(balanceAmount) > 0 && (
                  <div className="mb-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={balanceCollected}
                        onChange={(e) => setBalanceCollected(e.target.checked)}
                        className="mr-2"
                        required
                      />
                      <span
                        className={`${
                          isDarkMode ? "text-slate-400" : "text-gray-700"
                        }`}
                      >
                        I confirm that I've collected ${balanceAmount} from the
                        customer <span className="text-red-500">*</span>
                      </span>
                    </label>
                  </div>
                )}
                {rewardSuccess && (
                  <div className="mb-4">
                    <p className="text-green-500">
                      Customer has been rewarded successfully!
                    </p>
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div className="mt-6 flex justify-end">
          {selectedAction === "reward" && isRewardConfirmed ? (
            <>
              <button
                onClick={handleCancel}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleReward}
                disabled={
                  authorizationStatus === "pending" ||
                  (parseFloat(balanceAmount) > 0 && !balanceCollected)
                }
                className={`px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  authorizationStatus === "pending" ||
                  (parseFloat(balanceAmount) > 0 && !balanceCollected)
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-blue-600"
                }`}
              >
                <TrophyIcon className="h-5 w-5 mr-2 inline-block" />
                Reward
              </button>
            </>
          ) : selectedAction === "sell" ? (
            <button
              onClick={handleSell}
              disabled={!selectedGiftcard || !paymentConfirmed}
              className={`px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                !selectedGiftcard || !paymentConfirmed
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-blue-600"
              }`}
            >
              <CreditCardIcon className="h-5 w-5 mr-2 inline-block" />
              Send
            </button>
          ) : selectedAction === "refund" ? (
            <button
              onClick={handleRefund}
              disabled={
                !refundAmount ||
                parseFloat(refundAmount) <= 0 ||
                !refundConfirmed
              }
              className={`px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                !refundAmount ||
                parseFloat(refundAmount) <= 0 ||
                !refundConfirmed
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-blue-600"
              }`}
            >
              <TicketIcon className="h-5 w-5 mr-2 inline-block" />
              Refund
            </button>
          ) : (
            <button
              onClick={handleNext}
              disabled={isNextDisabled}
              className={`px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                isNextDisabled
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-blue-600"
              }`}
            >
              {selectedAction === "reward" && (
                <TrophyIcon className="h-5 w-5 mr-2 inline-block" />
              )}
              {selectedAction === "sell" && (
                <CreditCardIcon className="h-5 w-5 mr-2 inline-block" />
              )}
              {selectedAction === "refund" && (
                <TicketIcon className="h-5 w-5 mr-2 inline-block" />
              )}
              {selectedAction === "sell"
                ? "Send"
                : selectedAction === "refund"
                ? "Refund"
                : "Next"}
            </button>
          )}
        </div>
      </div>
      {showMessageModal && (
        <SuccessErrorModal 
          message={message} 
          onClose={closeMessageModal} 
          theme={theme}
        />
      )}
    </div>
  );
};

export default CustomerDetails;
