import { useState, useRef, useEffect } from "react";
import { useTheme } from "../contexts/ThemeContext";
import { io } from "socket.io-client";
import { 
  PaperAirplaneIcon,
  ArrowLeftIcon
} from "@heroicons/react/24/outline";

const Chat = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageText, setMessageText] = useState("");
  const socket = useRef(null);
  const [chatList, setChatList] = useState([]);
  const [isContactsLoaded, setIsContactsLoaded] = useState(true);
  const [messages, setMessages] = useState([]);
  const desktopMessagesContainerRef = useRef(null);
  const mobileMessagesContainerRef = useRef(null);

  const limit = 50;
  const pagination = false;

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    socket.current = io(process.env.REACT_APP_WEBSOCKET_URL, {
      auth: { token },
      path: '/rest/v2/chat/socket.io',
      transports: ['websocket'],
    });

    socket.current.on("connect", () => {
      console.log("Connected to WebSocket server");
      setTimeout(() => {
        socket.current.emit("list-chats", { limit, page: 1, pagination });
        setIsContactsLoaded(false);
      }, 500);
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []); // Remove selectedChat dependency to avoid re-setting up socket connection

  // Update the new-msg event listerner whenever a new chat is selected
  // Refer https://stackoverflow.com/questions/63309941/react-state-resets-with-socket-io
  useEffect(() => {
    // Remove the previous listener
    socket.current.off("new-msg");

    let foundCustomer = false; // flag to check if the sender is present in the currently loaded chat list

    socket.current.on("new-msg", (data) => {
      // Update the chat message view if the sender id is matching the currently selected chat's customer id
      if (selectedChat && selectedChat.customerId === data.senderId) {
        foundCustomer = true;
        console.log("new-msg: setting new msg");
        setMessages((prev) => [...prev, data]);
        scrollToBottom();
      } else {
        console.log("new-msg: incrementing new msg count");
        // Update unread count in chat list for the specific chat
        setChatList((prevList) =>
          prevList.map((chat) => {
            if(chat.customerId === data.senderId) {
              foundCustomer = true;
              return { ...chat, totalUnreadByB: (chat.totalUnreadByB || 0) + 1 };
            } else{return chat;}
          }
          )
        );
      }

      // Getting the list of chats if a message is from a customer that is not in the currently loaded chat list
      if(!foundCustomer) {
        socket.current.emit("list-chats", { limit, page: 1, pagination });
      }
    });

    socket.current.off("Chat"); // close the previous listener
    socket.current.on("chat", (chatData) => {
      setMessages(chatData.chat);
      // Scroll to bottom when loading a chat with slight delay to ensure rendering
      setTimeout(scrollToBottom, 100);
      
      // Mark messages as read for the selected chat
      if (selectedChat) {
        setChatList(prevList => 
          prevList.map(chat => 
            chat.customerId === selectedChat.customerId 
              ? { ...chat, totalUnreadByB: 0 } 
              : chat
          )
        );
      }
    });

  }, [selectedChat]);

  // Update the chats-list event listener whenever a new msg comes from customer that's not already in the current chats list
  // Refer https://stackoverflow.com/questions/63309941/react-state-resets-with-socket-io
  useEffect(() => {
    socket.current.off("chats-list");
    // Updated chat list handling to maintain stable order
    socket.current.on("chats-list", (data) => {
      setChatList(data.conversationList);
    });
  }, [chatList]);
  
  const handleChatClick = (customerId) => {
    const selectedChatInfo = chatList.find(chat => chat.customerId === customerId);
    if (socket.current && socket.current.connected) {
      socket.current.emit("get-chat", { memberId: customerId, page: 1, pageSize: 200, pagination });
    }
    
    setSelectedChat(selectedChatInfo || { customerId });
  };

  const scrollToBottom = () => {
    // Scroll desktop view
    if (desktopMessagesContainerRef.current) {
      desktopMessagesContainerRef.current.scrollTop = desktopMessagesContainerRef.current.scrollHeight;
    }
    
    // Scroll mobile view
    if (mobileMessagesContainerRef.current) {
      mobileMessagesContainerRef.current.scrollTop = mobileMessagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Use a short timeout to ensure DOM has updated
    setTimeout(scrollToBottom, 0);
  }, [messages]);
  
  const handleSendMessage = () => {
    if (messageText.trim() && selectedChat) {
      const messageData = {
        recipientId: selectedChat.customerId,
        message: messageText
      };
      
      if (socket.current && socket.current.connected) {
        socket.current.emit("new-msg", messageData);
      }

      setMessages(prevMessages => [
        ...prevMessages,
        { 
          _id: Date.now(),
          from: "B",
          sentAt: new Date().toISOString(),
          message: messageText,
          isReadByB: true,
          isReadByC: false
        }
      ]);

      setMessageText("");
    }
  };
  
  const handleBack = () => {
    setSelectedChat(null);
  };

  // Handle pressing Enter to send a message
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <>
    {/* dekstop devices */}
    <div className="hidden md:block container mx-auto px-4 py-6">
      <h1 className={`text-2xl font-semibold mb-6 ${isDarkMode ? "text-white" : "text-gray-800"}`}>
        Chat
      </h1>

      <div className="relative flex flex-col md:flex-row h-[calc(100vh-200px)] rounded-lg shadow-lg overflow-hidden">
        {/* Chat List */}
        <div className={`w-full md:w-1/3 border-r ${isDarkMode ? "bg-slate-800 border-slate-700" : "bg-white border-gray-200"}`}>
          <div className="h-full overflow-y-auto">
            {isContactsLoaded ? (
              <div className="flex justify-center items-center p-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              chatList.map((chat) => (
                <div
                  key={chat.customerId}
                  className={`p-4 cursor-pointer transition-colors flex flex-row justify-between ${
                    selectedChat?.customerId === chat.customerId
                      ? isDarkMode
                        ? "bg-slate-700"
                        : "bg-gray-100"
                      : isDarkMode
                        ? "hover:bg-slate-700"
                        : "hover:bg-gray-50"
                  } ${isDarkMode ? "border-slate-700" : "border-gray-200"}`}
                  onClick={() => handleChatClick(chat.customerId)}
                >
                  <h3 className={`font-semibold w-fit ${isDarkMode ? "text-white" : "text-gray-800"}`}>
                    {chat.customerName}
                  </h3>
                  {chat.totalUnreadByB > 0 && (
                    <span className="ml-2 bg-green-500 text-white text-xs font-medium px-2 py-1 rounded-full">
                      {chat.totalUnreadByB}
                    </span>
                  )}
                </div>
              ))
            )}
          </div>
        </div>

        {/* Messages Area */}
        <div className={`w-full md:w-2/3 flex flex-col h-full ${isDarkMode ? "bg-slate-900" : "bg-gray-50"}`}>
          {selectedChat ? (
            <div className="flex flex-col h-full">
              {/* Sticky Header */}
              <div className={`flex items-center p-3 border-b ${isDarkMode ? "border-slate-700 bg-slate-800" : "border-gray-200 bg-white"}`}>
                <button onClick={handleBack} className="mr-2">
                  <ArrowLeftIcon className="h-6 w-6 text-gray-700 dark:text-gray-300" />
                </button>
                <h2 className="text-lg font-semibold">{selectedChat.customerName}</h2>
              </div>

              {/* Messages */}
              <div 
                ref={desktopMessagesContainerRef}
                className="flex-1 overflow-y-auto px-4 py-2"
              >
                {messages.map((message) => (
                  <div key={message._id} className={`flex flex-col mb-2 ${message.from === "B" ? "items-end" : "items-start"}`}>
                    <div 
                      className={`px-4 py-2 rounded-xl text-sm max-w-[75%]  ${
                        message.from === "B"
                          ? isDarkMode 
                            ? "bg-blue-600 text-white"
                            : "bg-blue-500 text-white"
                          : isDarkMode 
                            ? "bg-slate-700 text-white"
                            : "bg-white text-gray-800"
                      }`}
                    >
                      <p className="text-lg">{message.message}</p>
                      <span className="text-xs text-gray-200 ml-2">
                        {new Date(message.sentAt).toLocaleString()}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              {/* Input Box */}
              <div className={`px-6 py-3 border-t flex items-center ${isDarkMode ? "border-slate-700 bg-slate-800" : "border-gray-200 bg-white"}`}>
                <textarea
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type a message"
                  className={`flex-1 resize-none p-2 rounded-lg focus:outline-none ${
                    isDarkMode
                      ? "bg-slate-700 text-white placeholder-slate-400"
                      : "bg-gray-100 text-gray-800 placeholder-gray-500"
                  }`}
                  rows={1}
                />
                <button onClick={handleSendMessage} className="ml-3 p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                  <PaperAirplaneIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          ) : (
            <div className="h-full flex items-center justify-center text-gray-500 dark:text-gray-400">
              Select a chat to view messages
            </div>
          )}
        </div>
      </div>
    </div>

    {/* mobile devices */}
    <div className="md:hidden container mx-auto px-4 py-6">
      <h1 className={`text-2xl font-semibold mb-6 ${isDarkMode ? "text-white" : "text-gray-800"}`}>
        Chat
      </h1>

      <div className="relative flex flex-col md:flex-row h-[calc(100vh-200px)] rounded-lg shadow-lg overflow-hidden">
        {/* Chat List */}
        {!selectedChat && (
          <div className={`w-full md:w-1/3 border-r ${isDarkMode ? "bg-slate-800 border-slate-700" : "bg-white border-gray-200"}`}>
            <div className="h-full overflow-y-auto">
              {isContactsLoaded ? (
                <div className="flex justify-center items-center p-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                </div>
              ) : (
                chatList.map((chat) => (
                  <div
                    key={chat.customerId}
                    className={`p-4 cursor-pointer transition-colors flex flex-row justify-between ${
                      selectedChat?.customerId === chat.customerId
                        ? isDarkMode
                          ? "bg-slate-700"
                          : "bg-gray-100"
                        : isDarkMode
                          ? "hover:bg-slate-700"
                          : "hover:bg-gray-50"
                    } ${isDarkMode ? "border-slate-700" : "border-gray-200"}`}
                    onClick={() => handleChatClick(chat.customerId)}
                  >
                    <h3 className={`font-semibold ${isDarkMode ? "text-white" : "text-gray-800"}`}>
                      {chat.customerName}
                    </h3>
                    {chat.totalUnreadByB > 0 && (
                      <span className="ml-2 bg-green-500 text-white text-xs font-medium px-2 py-1 rounded-full">
                        {chat.totalUnreadByB}
                      </span>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        )}

        {/* Messages Area */}
        {selectedChat && (
          <div className={`flex flex-col w-full h-full ${isDarkMode ? "bg-slate-900" : "bg-gray-50"}`}>
            <div className={`flex items-center p-3 border-b ${isDarkMode ? "border-slate-700 bg-slate-800" : "border-gray-200 bg-white"}`}>
              <button onClick={handleBack} className="mr-2">
                <ArrowLeftIcon className="h-6 w-6 text-gray-700 dark:text-gray-300" />
              </button>
              <h2 className="text-lg font-semibold">{selectedChat.customerName}</h2>
            </div>

            {/* Messages */}
            <div 
              ref={mobileMessagesContainerRef}
              className="flex-1 overflow-y-auto px-4 py-2"
            >
              {messages.map((message) => (
                <div key={message._id} className={`flex flex-col mb-2 ${message.from === "B" ? "items-end" : "items-start"}`}>
                  <div 
                    className={`px-4 py-2 rounded-xl text-sm max-w-[75%] ${
                      message.from === "B"
                        ? isDarkMode 
                          ? "bg-blue-600 text-white"
                          : "bg-blue-500 text-white"
                        : isDarkMode 
                          ? "bg-slate-700 text-white"
                          : "bg-white text-gray-800"
                    }`}
                  >
                    <p className="text-lg">{message.message}</p>
                    <span className="text-xs text-gray-300 ml-2">
                      {new Date(message.sentAt).toLocaleString()}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            {/* Input Box */}
            <div className={`px-6 py-3 border-t flex items-center ${isDarkMode ? "border-slate-700 bg-slate-800" : "border-gray-200 bg-white"}`}>
              <textarea
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type a message"
                className={`flex-1 resize-none p-2 rounded-lg focus:outline-none ${
                  isDarkMode
                    ? "bg-slate-700 text-white placeholder-slate-400"
                    : "bg-gray-100 text-gray-800 placeholder-gray-500"
                }`}
                rows={1}
              />
              <button onClick={handleSendMessage} className="ml-3 p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                <PaperAirplaneIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default Chat;