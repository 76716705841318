import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTheme } from '../contexts/ThemeContext';
import CustomerSearch from './CustomerSearch';
import axios from 'axios';
import { 
  Bars3Icon,
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  ChatBubbleLeftRightIcon,
  SunIcon,
  MoonIcon,
  BuildingStorefrontIcon
} from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import { useMediaQuery } from 'react-responsive';

const Header = ({ onMenuClick, onThemeToggle, onNavigate, user, store }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const searchInputRef = useRef(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  
  // New state to track if there are new messages
  const [hasNewMessages, setHasNewMessages] = useState(false);

  const hasChatAccess = user.userType === "Owner" || user.chatAccess === true;

  // Function to check for new messages
  const checkForNewMessages = async () => {
    try {
      const url = user.userType === 'Staff' ? '/staff/hasNewMsg' : '/business/hasNewMsg';
      const response = await axiosInstance.get(url , { skipLoadingToast: true });
      setHasNewMessages(response.result); // Set state based on the result property
    } catch (error) {
      console.error('Error checking for new messages:', error);
    }
  };

  // Check for new messages on component mount and set interval
  useEffect(() => {
    if (hasChatAccess) {
      checkForNewMessages(); // Initial check
      const intervalId = setInterval(checkForNewMessages, 120000); // Check every 10 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, []);

  const handleSearch = async (query) => {
    // TODO: Implement customer search API call
    console.log('Searching for:', query);
  };

  // Navigate without focusing search input
  const handleNavigation = (route) => {
    onNavigate(route);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
      // Still navigate to login even if API fails
      navigate('/login', { replace: true });
    }
  };

  // Check if the screen is small
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  return (
    <header className={`border-b ${
      isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-gray-200'
    }`}>
      <div className="flex items-center justify-between px-4 h-16">
        <div className="flex items-center flex-1">
          <button
            type="button"
            onClick={onMenuClick}
            className={`p-2 rounded-lg lg:hidden ${
              isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
            }`}
          >
            <Bars3Icon className="h-6 w-6" />
          </button>

          <div className="flex-1 max-w-3xl mx-4">
            <CustomerSearch 
              onSearch={handleSearch}
              ref={searchInputRef}
            />
          </div>
        </div>

        {/* Right side buttons */}
        <div className="flex items-center space-x-2">
          {/* Chat Button - only show on larger screens */}
          {hasChatAccess && (
            <button
              type="button"
              onClick={() => handleNavigation('chat')}
              className={`relative p-2 rounded-full transition-colors ${
                isDarkMode
                  ? 'text-slate-400 hover:text-slate-200 hover:bg-slate-700'
                  : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
              }`}
              aria-label="Open chat"
            >
              <ChatBubbleLeftRightIcon className="h-6 w-6" />
              {hasNewMessages && (
                <span className="absolute top-0 right-0 h-2.5 w-2.5 rounded-full bg-red-500" />
              )}
            </button>
          )}

          {/* Theme Toggle */}
          {!isSmallScreen && (
            <button
              type="button"
              onClick={onThemeToggle}
              className={`p-2 rounded-full transition-colors ${
                isDarkMode
                ? 'text-slate-400 hover:text-slate-200 hover:bg-slate-700'
                : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
              }`}
            aria-label={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
            >
              {isDarkMode ? (
                <SunIcon className="h-6 w-6" />
              ) : (
                <MoonIcon className="h-6 w-6" />
              )}
            </button>
          )}

          {/* Logout Button - only show on larger screens */}
          {!isSmallScreen && (
            <button
              type="button"
              onClick={handleLogout}
              className={`p-2 rounded-full transition-colors ${
                isDarkMode
                  ? 'text-slate-400 hover:text-slate-200 hover:bg-slate-700'
                  : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
              }`}
              aria-label="Logout"
            >
              <ArrowRightOnRectangleIcon className="h-6 w-6" />
            </button>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center px-4 py-2">
        <div className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
          Hi, {user.name} <span className="text-xs font-normal">({user.userType})</span>
        </div>
        {store && (
          <div className="flex items-center space-x-1">
            <BuildingStorefrontIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
            <span className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
              {store.storeName}
            </span>
            <span className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              | Counter: {store.counter}
            </span>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header; 