import axiosInstance from './axiosConfig';

// Move the generateUUID function outside the class or define it as a static method
const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

class AuthService {
  async sendOTP({ email, userType, businessHandle }) {
    // Convert userType to match API format
    const userTypeMap = {
      'Owner': 'B',
      'Staff': 'S'
    };

    const apiUserType = userTypeMap[userType];

    const requestBody = {
      userType: apiUserType,
      user: {
        email: email,
        ...(apiUserType === 'S' && { userName: businessHandle })
      }
    };

    const response = await axiosInstance.post('/user/loginotp', requestBody);

    return {
      success: true,
      message: response.message || 'OTP sent successfully',
      otp: response.otp // Remove this in production - OTP should come via email
    };
  }

  async verifyOTP({ email, otp, userType, businessHandle }) {
    // Convert userType to match API format
    const userTypeMap = {
      'Owner': 'B',
      'Staff': 'S'
    };

    const apiUserType = userTypeMap[userType];

    // Get device info
    const deviceInfo = {
      uuid: typeof crypto !== 'undefined' && crypto.randomUUID ? crypto.randomUUID() : generateUUID(), // Use fallback if crypto is not available
      platform: navigator.platform,
      model: navigator.userAgent,
      make: navigator.vendor || 'unknown',
      name: navigator.appName,
      regToken: 'web-token', // This should be your actual push notification token
      appVersion: '1.0.0',
      isOwnerDevice: apiUserType === 'B'
    };

    const requestBody = {
      userType: apiUserType,
      client: 'Web',
      user: {
        email: email,
        otp: parseInt(otp),
        device: deviceInfo
      }
    };

    // Add userName for staff
    if (apiUserType === 'S' && businessHandle) {
      requestBody.user.userName = businessHandle;
    }

    // Add store info if available from localStorage
    const storedStore = localStorage.getItem('store');
    if (storedStore) {
      const { storeId, counter } = JSON.parse(storedStore);
      requestBody.storeId = storeId;
      requestBody.counter = counter;
      if (deviceInfo.isOwnerDevice) {
        deviceInfo.storeId = storeId;
        deviceInfo.displayName = `${counter} - Web`;
      }
    }
    console.log("in here");
    const response = await axiosInstance.post('/user/verifyloginotp', requestBody);

    // Store token
    if (response.token) {
      localStorage.setItem('token', response.token);
    }

    return {
      success: true,
      user: {
        ...response.data,
        userType // Keep the frontend userType format
      },
      token: response.token
    };
  }

  async resendOTP({ email, userType, businessHandle }) {
    // Reuse sendOTP method since it's the same endpoint
    return this.sendOTP({ email, userType, businessHandle });
  }

  async logout() {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      if(userType === 'Owner'){
        await axiosInstance.get('/business/logout');
      }
      else if(userType === 'Staff'){
        localStorage.clear();
      }
      // Clear local storage and other cleanup will be handled by the AuthContext
      return { success: true };
    } catch (error) {
      console.error('Logout failed:', error);
      // Still return success as we want to clear local state regardless of API success
      return { success: true };
    }
  }
}

export const authService = new AuthService();

export const login = async (credentials) => {
  try {
    const response = await fetch('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    const data = await response.json();

    if (!response.ok) {
      return { err: data.message || 'Login failed' };
    }

    return {
      data: data.user,
      message: data.message || 'Successfully logged in!'
    };
  } catch (error) {
    return { err: 'Network error occurred' };
  }
};

export const logout = async () => {
  try {

    const response = await fetch('/api/auth/logout', {
      method: 'POST',
    });

    const data = await response.json();

    if (!response.ok) {
      return { err: data.message || 'Logout failed' };
    }

    return { message: data.message || 'Successfully logged out!' };
  } catch (error) {
    return { err: 'Network error occurred' };
  }
}; 